import Link from "next/link";
import StylesIcon from "@/styles/Icons.module.css";

const FooterSocial = () => {
  return (
    <div className="col-md-4 socials sidenav__socials justify-content-md-end">
      <Link
        href="https://www.facebook.com/tbn24usa"
        target={"_blank"}
        className="social social-facebook"
        title="facebook"
        prefetch={false}
      >
        <i
          className={`${StylesIcon.facebook__icon}`}
          style={{ color: "#fff" }}
        ></i>
      </Link>
      <Link
        href="https://twitter.com/tbn24usa"
        target={"_blank"}
        className="social social-twitter"
        title="twitter"
        prefetch={false}
      >
        <i
          className={`${StylesIcon.twitter__icon}`}
          style={{ color: "#fff" }}
        ></i>
      </Link>
      <Link
        href="https://www.instagram.com/tbn24ny/"
        target={"_blank"}
        className="social social-instagram"
        title="instagram"
        prefetch={false}
      >
        <i
          className={`${StylesIcon.instagram__icon}`}
          style={{ color: "#fff" }}
        ></i>
      </Link>
      <Link
        href="https://www.youtube.com/tbn24usa"
        target={"_blank"}
        className="social social-youtube"
        title="youtube"
        prefetch={false}
      >
        <i
          className={`${StylesIcon.youtube__icon}`}
          style={{ color: "#fff" }}
        ></i>
      </Link>
    </div>
  );
};

export default FooterSocial;
